@use 'base/variables' as v;
@use 'base/mixins' as m;

.main{
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  // @include m.mq-full-hd{
  //   height: 700px;
  // }
  @media screen and (max-width: 1250px) {
    height: auto;
  }
}

.main.static{
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
