@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-trabaja-con-nosotros-02{
  padding: 0rem 0rem 7rem 0rem;
  .content-block{
    padding: 7rem 2.5rem 12rem 2.5rem;
    background: url('./../../build/img/bg-trabaja-con-nosotros-section-02.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .item-box{
      img:nth-of-type(1){
        margin: 0rem auto 3rem auto;
      }
    }
  }
}