@use 'base/variables' as v;
@use 'base/mixins' as m;

.content-terminos-condiciones{
  overflow-y: scroll;
  max-height: 400px;
  .content{
    margin: 0rem 0rem .8rem 0rem;
    p.title{
      font-family: v.$font-bold-01;
      font-size: v.$body-04;
      text-transform: uppercase;
      margin: 0rem 0rem 2rem 0rem;
    }
    p.subtitle{
      // margin: 5rem 0rem 0rem 0rem;
      font-family: v.$font-regular-01;
      font-weight: bold;
      font-size: v.$body-04;
      text-transform: uppercase;
      margin: 0rem 0rem 1.6rem 0rem;
    }
    p.body{
      font-family: v.$font-regular-01;
      font-size: v.$body-04;
      margin: 0rem 0rem 1.2rem 0rem;
    }
    ol{
      list-style-position: inside;
      margin: 0rem 0rem 1.2rem 0rem;
      li{
        font-family: v.$font-regular-01;
        font-size: v.$body-04;
      }
    }
    ul{
      margin: 0rem 0rem 1.2rem 0rem;
      li{
        font-family: v.$font-regular-01;
        font-size: v.$body-04;
      }
    }
  }
}