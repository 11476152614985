@use 'variables' as v;
@use 'mixins' as m;

/* GLOBALES */
html {
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
}

body{
  font-size: v.$body-01;
  font-family: v.$font-regular-01;
  color: v.$negro;
  @include m.mq-sm{
    font-size: v.$body-03;
  }
}

// SCROLLBAR
body::-webkit-scrollbar {
  -webkit-appearance: none;
}
body::-webkit-scrollbar:vertical {
  width:1.7rem;
}
body::-webkit-scrollbar-button:increment, body::-webkit-scrollbar-button {
  display: none;
} 
body::-webkit-scrollbar:horizontal {
  height: 1.7rem;
}
body::-webkit-scrollbar-thumb {
  // background-color: v.$dorado-02;
  background-color: v.$base-02;
  // border-radius: 20px;
  // border-radius: .5rem;
  border: 1px solid rgba(0,0,0,.1);
}
// body::-webkit-scrollbar-track {
//   border-radius: 10px;  
// }

/* FONT SIZING */
h1, h2, h3, h4, h5, h6, p, li, a{
  margin: 0rem;
  padding: 0rem;
  line-height: 1.1;
}

h1{
  font-size: v.$h1-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h1-sm;
  }
}
h2{
  font-size: v.$h2-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h2-sm;
  }
}
h3{
  font-size: v.$h3-xl;
  font-family: v.$font-bold-01;
  @include m.mq-sm{
    font-size: v.$h3-sm;
  }
}
h4{
  font-size: v.$h4-xl;
  font-family: v.$font-regular-01;
  @include m.mq-sm{
    font-size: v.$h4-sm;
  }
}
h5{
  font-size: v.$h5-xl;
  font-family: v.$font-regular-01;
  @include m.mq-sm{
    font-size: v.$h5-sm;
  }
}
h6{
  font-size: v.$h6-xl;
  font-family: v.$font-regular-01;
  @include m.mq-sm{
    font-size: v.$h6-sm;
  }
}
p{
  font-size: v.$body-03;
  font-family: v.$font-regular-01;
  // @include m.mq-sm{
  //   font-size: v.$body-03;
  // }
  @include m.mq-sm{
    font-size: v.$body-04;
  }
  @include m.mq-xs-02{
    font-size: v.$body-05;
  }
  strong{
    font-family: v.$font-bold-01;
    // font-weight: bolder;
  }
}
a{
  font-size: v.$body-03;
  text-decoration: none;
  @include m.mq-sm{
    font-size: v.$body-04;
  }
  @include m.mq-xs-02{
    font-size: v.$body-05;
  }
  strong{
    font-family: v.$font-bold-01;
  }
  &:focus{
    text-decoration: none;
  }
  &:hover{
    text-decoration: none;
  }
}
ul{
  list-style: none;
}
ul, ol{
  padding: 0rem;
  margin: 0rem;
  li{
    font-size: v.$body-03;
    @include m.mq-sm{
      font-size: v.$body-04;
    }
    @include m.mq-xs-02{
      font-size: v.$body-05;
    }
    strong{
      font-family: v.$font-bold-01;
    }
  }
}

.center-recaptcha{
  // display: flex;
  // justify-content: center;
  // padding-top: .5rem;
  padding-bottom: 1rem;
  @media screen and (max-width: v.$md) {
    padding-bottom: 2rem;
  }
}

iframe{
  width: 100% !important;
}

// RECAPTCHA
.g-recaptcha{
  position: relative;
  width: 225px;
  height: 60px;
  @media screen and (max-width: v.$sm) {
    height: 55px;
  }
}

.g-recaptcha > :first-child {
  transform: scale(.8);
  // position: absolute; 
  // left: 0; 
  transform-origin: 0% 0;
  @media screen and (max-width: v.$xs-01) {
    transform: scale(.65);
    // transform-origin: 0 0;
    // margin-left: 0px; 
  }
}

// Utilidades
.reset-padding{
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.center-block{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
}

.center-btn-responsive{
  @media screen and (max-width: v.$md) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1040;
  z-index: 1700;
  width: 100vw;
  height: 100vh;
  // background-color: #000;
  background-color: v.$base-alfa-01;
}

.grid-section-controller{
  display: grid;
  grid-template-columns: 1fr;
  .section-form.scrolled{
    grid-row: 7/8;
  }
}

// Cookies Consent - Personalized CSS
.cc_div {
  color: v.$base-01 !important;
}

.cc_div .cc-link {
  color: v.$base-01 !important;
  border-bottom: .1rem solid v.$base-01 !important;
}

#c-bns button:first-child, #s-bns button:first-child {
  background: v.$base-01 !important;
  color: v.$blanco !important;
}

.cc_div .c-bn {
  color: v.$base-01 !important;
}

#s-cnt .p {
  color: v.$negro !important;
}

.cc_div .act .b-bn .exp::before, .cc_div .b-bn .exp::before {
  border-color: v.$base-01 !important;
}

.cc_div .b-tg .c-tgl:checked~.c-tg {
  background: v.$base-01 !important;
}

#s-c-bn::after, #s-c-bn::before {
  background: v.$base-01 !important;
}

#s-c-bn::after, #s-c-bn::before {
  background: v.$base-01 !important;
}

/* Tooltips */
.tooltip {
  // position: absolute;
  // z-index: 1080;
  // display: block;
  // margin: 0;
  // font-family: var(--bs-font-sans-serif);
  // font-style: normal;
  // font-weight: 400;
  // line-height: 1.5;
  // text-align: left;
  // text-align: start;
  // text-decoration: none;
  // text-shadow: none;
  // text-transform: none;
  // letter-spacing: normal;
  // word-break: normal;
  // word-spacing: normal;
  // white-space: normal;
  // line-break: auto;
  // font-size: 0.875rem;
  // word-wrap: break-word;
  // opacity: 0;

  font-size: v.$body-03;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: v.$blanco;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: v.$blanco;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: v.$blanco;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: v.$blanco;
}

.tooltip-inner {
  // max-width: 200px;
  // padding: 0.25rem 0.5rem;
  // color: #fff;
  // text-align: center;
  // background-color: #000;
  // border-radius: 0.25rem;
  
  color: v.$base-02;
  background: v.$blanco;
  // box-shadow: 0rem 0rem 1rem .3rem rgba(239,28,34,.25);
  box-shadow: 0rem 0rem 1rem .3rem rgba(0,0,0,.3);
  padding: 1.5rem 1rem;
  line-height: 1.2;
}


/* BORDERS */
.align-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

}
.align-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.border-servicios{
  width: 50%;
  border: .1rem solid v.$base-02;
}