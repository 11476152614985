/* VARIABLES */
// Fuentes
$font-black-01: 'Raleway-Black';

$font-bold-01: 'Raleway-Bold';

$font-regular-01: 'Raleway-Regular';



// Tamaño de Media Queries
$full-hd: 1920px;
$xl: 1560px;
$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;
$xs-01: 480px;
$xs-02: 380px;

// Font size
// XL 
$h1-xl: 6rem;
$h2-xl: 3rem;
$h3-xl: 2.4rem;
$h4-xl: 2.2rem;
$h5-xl: 2.0rem;
$h6-xl: 1.8rem;
$li-xl: 1.6rem;

// SM
$h1-sm: 4.5rem;
$h2-sm: 3rem;
$h3-sm: 2.4rem;
$h4-sm: 2.2rem;
$h5-sm: 2.0rem;
$h6-sm: 1.8rem;
$li-sm: 1.6rem;

// Body sizes
$body-01: 2.0rem;
$body-02: 1.8rem;
$body-03: 1.6rem;
$body-04: 1.4rem;
$body-05: 1.2rem;
$body-06: 1rem;

// Colores
$base-01: #7F050E;
$base-02: #BB0C0C;
$base-03: #EF1C22;

$blanco: #FFF;
$negro: #201E1F;

$gris-01: rgba(0, 0, 0, .3);
$gris-02: #6A6A6A;
$gris-03: #F2F2F2;
$gris-04: rgba(255, 255, 255, .75);

$rojo-alerta: #7B2627;

// Colores en canal alfa
// $base-alfa-01: rgba(127,5,14,1);
$base-alfa-01: rgba(32,30,31,1);
$negro-alfa-01: rgba(0,0,0,.8);

// Gradientes
$gradient-alfa-01: linear-gradient(90deg, $base-alfa-01 0%, $base-alfa-01 100%);

