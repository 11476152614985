@use 'base/variables' as v;
@use 'base/mixins' as m;

.modal-politica{
  z-index: 1800;
  .modal-dialog{
    position: relative;
    max-width: 95%;
    margin-top: 0rem;
    @include m.mq-sm{
      max-width: 90%;
      margin: 0rem auto 0rem auto;
    }
  }
  .modal-body{
    position: relative;
    padding: 0rem;
    padding: 7rem 2rem 1rem 2rem;
    max-height: 480px;
    @include m.mq-sm{
      // padding: 5rem 1.5rem 1.5rem 1.5rem;
      padding: 5rem 2rem 1rem 2rem;
    }
    .close-modal{
      position: absolute;
      right: 0;
      top: 2rem;
      z-index: 100;
      cursor: pointer;
      @include m.mq-sm{
        top: 1rem;
      }
      span{
        i:before{
          // background: v.$blanco;
          font-size: v.$h2-xl;
          color: v.$base-01;
          padding: 2rem;
          @include m.mq-sm{
            padding: 1rem;
          }
        }
      }
    }
  }
}