@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-form{
  padding: 0rem 0rem 7rem 0rem;
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  form{
    padding: 4rem 4.5rem 3.5rem 4.5rem;
    border-radius: 5rem;
    background: v.$base-01;
    @include m.mq-sm{
      padding: 3rem 2.5rem 3.5rem 2rem;
    }
    h3:nth-of-type(1){
      color: v.$blanco;
      text-align: center;
      margin: 0rem 0rem 3rem 0rem;
    }
    .form-group{
      padding: 0rem 0rem 1rem 0rem;
    }
    .form-group:last-of-type{
      padding: 0rem 0rem 0rem 0rem;
    }
    .form-control{
      display: block;
      width: 100%;
      padding: 1.25rem 2rem;
      font-size: v.$body-03;
      font-weight: 400;
      line-height: 1.5;
      color: v.$base-03;
      background-color: v.$blanco;
      background-clip: padding-box;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 2.5rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      @include m.mq-sm{
        border-radius: 1.5rem;
        padding: .75rem 1rem;
        font-size: v.$body-04;
      }
    }
    .form-control.picker{
      background: url('./../../build/img/bg-select-departamento.webp'), v.$blanco;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 100.25% center;
      cursor: pointer;
    }
    ::placeholder {
      color: v.$base-03;
    }
    .checkbox{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: v.$body-03;
      color: v.$blanco;
      gap: 0rem 1rem;
      input{
        margin-bottom: .25rem;
      }
      span{
        a{
          font-size: v.$body-03;
          color: v.$blanco;
          &:hover{
            color: v.$base-03;
          }
        }
      }
    }

    .form-contacto.collapse{
      margin-top: .5rem;
      .card{
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: none;
        border-radius: .5rem;
      }
      .card-body{
        padding: 0rem 0rem;
        ul{
          display: flex;
          flex-direction: column;
          li{
            font-size: v.$body-03;
            color: v.$base-01;
            padding: 1.5rem 2rem 1.5rem 2rem;
          }
          li:hover{
            background: v.$base-03;
            color: v.$blanco;
            cursor: pointer;
          }
        }
      }
    }
    .form-contacto.collapsing{
      margin-top: .5rem;
      .card{
        border: none;
        border-radius: .5rem;
      }
      .card-body{
        padding: 0rem 0rem;
        ul{
          display: flex;
          flex-direction: column;
          li{
            font-size: v.$body-03;
            color: v.$base-01;
            padding: 1.5rem 2rem 1.5rem 2rem;
          }
        }
      }
    }

    /* PRISTINE IMPLEMENTATION */
    .pristine-error.text-help{
      font-size: v.$body-04;
      color: v.$blanco;
      margin: .5rem 0rem 0rem 1rem;
    }

    .input-error{
      font-size: v.$body-04;
      color: v.$blanco;
      margin: .5rem 0rem 0rem 1rem;
    }
  }
}

.section-form.home{
  padding: 0rem 0rem 7rem 0rem;
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.section-form.servicios{
  padding: 0rem 0rem 7rem 0rem;
  position: relative;
  margin-top: -5rem;
  .content-block{
    display: block;
    .item-box{
      display: block;
    }
  }
  form{
    max-width: 652px;
    margin: 0 auto;
  }
}

.section-form.trabaja-con-nosotros{
  padding: 0rem 0rem 7rem 0rem;
  position: relative;
  margin-top: -5rem;
  @include m.mq-sm{
    position: static;
    margin-top: 3rem;
  }
  .content-block{
    display: block;
    .item-box{
      display: block;
    }
  }
  form{
    max-width: 652px;
    margin: 0 auto;
  }
}

.section-form.contactanos{
  padding: 0rem 0rem 0rem 0rem;
  position: relative;
  margin-top: -5rem;
  @include m.mq-sm{
    padding: 5rem 0rem 7rem 0rem;
    margin-top: 0rem;
    position: static;
  }
  .content-block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 3rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .item-box:nth-last-of-type(2){
      padding-right: 5rem;
      @include m.mq-sm{
        padding-right: 0rem;
      }
      ul{
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0rem;
        // border-left:.1rem solid v.$base-01;
        padding-bottom: 2.5rem;
        border-bottom: .1rem solid v.$base-01;
        // background: v.$base-02;
        // padding: 2.5rem 2.5rem 2.5rem 5rem;
        // border-radius: 5rem 0rem 0rem 5rem;
        li{
          color: v.$base-01;
          // color: v.$blanco;
          a{
            color: v.$base-01;
            // color: v.$blanco;
          }
        }
      }
      img:nth-of-type(1){
        margin: 3rem 0rem 2rem 0rem;
        max-width: 176px;
      }
      p:nth-of-type(1){
        text-align: justify;
      }
    }
  }
}


@keyframes transicion-envio{
  0%{
    transform: scale(initial);
  }
  20%{
    transform: scale(.9);
  }
  35%{
    transform: scale(1.1);
  }
  60%{
    transform: scale(0.25);
    opacity:1;
  }
  90%{
    transform: scale(0.25);
  }
  100%{
    opacity: 0;
  }
}

.animacion-form{
  animation-name: transicion-envio;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes opacidad-contenido{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.suavizar-opacidad{
  animation-name: opacidad-contenido;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.spinner-border.loader-form{
  color: v.$base-01;
  width: 60px;
  height: 60px;
  margin-top: 50px;
}
@media screen and (max-width: 575px) {
  .spinner-border.loader-form{
    margin-top: 35px;
  }
}

.tx-seccion-form-send {
  color: v.$base-01;
  font-family: v.$font-regular-01;
  font-size: v.$h4-xl;
  text-align: center;
  margin: 20px 0px 20px 0px;
  @media screen and (max-width: v.$sm) {
    font-size: v.$h4-sm;
  }
}

// RENEW LEAD
.renew-lead{
  padding: 4rem 4.5rem 3.5rem 4.5rem;
  border-radius: 5rem;
  background: v.$base-01;
  @include m.mq-sm{
    padding: 3rem 2.5rem 3.5rem 2rem;
  }
  p{
    color: v.$blanco;
    font-family: v.$font-regular-01;
    font-size: v.$h5-xl;
    text-align: center;
    margin: 0px 0px 20px 0px;
    @media screen and (max-width: v.$sm) {
      font-size: v.$h5-sm;
    }
  }
  p:last-of-type{
    margin: 0px 0px 30px 0px;
  }
  a{
    &:hover{
      cursor: pointer;
    }
  }
}