@use 'base/variables' as v;
@use 'base/mixins' as m;


#sliderMain.carousel{
  .carousel-item{
    position: relative;
    .item-box{
      // background: v.$gris-01;
      // border-radius: 2.5rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      // max-width: 50%;
      max-width: 65%;
      max-height: 37.5%;
      position: absolute;
      top:0;
      right: 0;
      bottom: 0;
      left:0;
      margin: auto;

      z-index: 400;
      @include m.mq-sm{
        max-width: 75%;
        max-height: 32.5%;
      }
    }
    h2{
      color: v.$blanco;
      font-family: v.$font-regular-01;
      // font-size: v.$h1-xl;
      font-size: 4.5rem;
      text-transform: uppercase;
      padding: 1rem 3rem;

      text-align: center;
      @include m.mq-lg{
        font-size: v.$h2-xl;
      }
      @include m.mq-sm{
        padding: 1rem 1.5rem;
        font-size: v.$h2-sm;
      }
    }
  }
  .carousel-indicators {
    bottom: 12.5rem;
    margin-bottom: 0rem;
    @include m.mq-full-hd{
      bottom: 14.5%;
    }
    @include m.mq-lg{
      bottom: 12.5%;
    }
    a{
      height: .5rem;
      width: 1.5rem;
      border-radius: .5rem;
      &.active{
        background-color: v.$base-03;
        width: 3rem;
      }
    }
  }
  .control-left{
    position: absolute;
    left: 5rem;
    bottom: 50%;
    cursor: pointer;
    opacity: .7;
    z-index: 500;
    &:hover{
      opacity: 1;
    }
    span{
      i:before{
        color: v.$blanco;
        font-size: v.$h1-xl;
      }
    }
  }
  .control-right{
    position: absolute;
    right: 5rem;
    bottom: 50%;
    cursor: pointer;
    opacity: .7;
    z-index: 500;
    &:hover{
      opacity: 1;
    }
    span{
      i:before{
        color: v.$blanco;
        font-size: v.$h1-xl;
      }
    }
  }
}

#sliderAliados.carousel{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 110px;
  gap:0rem 1rem;
  .carousel-item{
    img{
      padding: 0rem .75rem;
    }
  }
  .control-left{
    cursor: pointer;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    span{
      i:before{
        color: v.$base-01;
        font-size: v.$h2-xl;
      }
    }
  }
  .control-right{
    cursor: pointer;
    opacity: .7;
    &:hover{
      opacity: 1;
    }
    span{
      i:before{
        color: v.$base-01;
        font-size: v.$h2-xl;
      }
    }
  }
}

/*********************************************************************/
/* Bootstrap 5 Multiple Item Carousel */
/*********************************************************************/
#sliderAliados .carousel-item-next:not(.carousel-item-start),
#sliderAliados .active.carousel-item-end {
  transform: translateX(25%);
}

#sliderAliados .carousel-item-prev:not(.carousel-item-end),
#sliderAliados .active.carousel-item-start {
  transform: translateX(-25%);
}

@media (max-width: v.$sm) {
  #sliderAliados .carousel-item-next:not(.carousel-item-start),
  #sliderAliados .active.carousel-item-end {
    transform: translateX(33.3%);
  }

  #sliderAliados .carousel-item-prev:not(.carousel-item-end),
  #sliderAliados .active.carousel-item-start {
    transform: translateX(-33.3%);
  }
}
/* DEFECTO */
#sliderAliados .carousel-inner .carousel-item.active,
#sliderAliados .carousel-inner .carousel-item-next,
#sliderAliados .carousel-inner .carousel-item-prev {
  display: flex;
  align-items: center;
}

#sliderAliados .carousel-inner .carousel-item-right,
#sliderAliados .carousel-inner .carousel-item-left {
  transform: translateX(0);
}
