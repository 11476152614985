/* FONTS */
@font-face{
	font-family: 'Raleway-Black';
	src: url('./../../build/fonts/Raleway-Black.ttf') format("truetype");
	font-weight:normal;
	font-display:swap;
}
@font-face{
	font-family: 'Raleway-Bold';
	src: url('./../../build/fonts/Raleway-Bold.ttf') format("truetype");
	font-weight:normal;
	font-display:swap;
}
@font-face{
	font-family: 'Raleway-Regular';
	src: url('./../../build/fonts/Raleway-Regular.ttf') format("truetype");
	font-weight:normal;
	font-display:swap;
}

// ICOMOON
@font-face {
  font-family: 'icomoon';
  src:  url('./../../build/fonts/icomoon.eot?b342ug');
  src:  url('./../../build/fonts/icomoon.eot?b342ug#iefix') format('embedded-opentype'),
    url('./../../build/fonts/icomoon.ttf?b342ug') format('truetype'),
    url('./../../build/fonts/icomoon.woff?b342ug') format('woff'),
    url('./../../build/fonts/icomoon.svg?b342ug#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e912";
}
.icon-alimentacion-bases-datos:before {
  content: "\e900";
}
.icon-almacenamiento:before {
  content: "\e901";
}
.icon-asesorias-contables:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-down:before {
  content: "\e904";
}
.icon-estibado-productos:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-left:before {
  content: "\e907";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-manejo-inventarios:before {
  content: "\e909";
}
.icon-maquila:before {
  content: "\e90a";
}
.icon-menu:before {
  content: "\e90b";
}
.icon-operacion-maquinas-montacarga:before {
  content: "\e90c";
}
.icon-picking-packing:before {
  content: "\e90d";
}
.icon-right:before {
  content: "\e90e";
}
.icon-servicios-generales:before {
  content: "\e90f";
}
.icon-telefono-main:before {
  content: "\e910";
}
.icon-telefono-second:before {
  content: "\e911";
}
.icon-up:before {
  content: "\e913";
}
.icon-vaceo:before {
  content: "\e914";
}
