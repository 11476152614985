/* VARIABLES */
/** Media Queries **/
/* GLOBALES */
html {
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
}

body {
  font-size: 2rem;
  font-family: "Raleway-Regular";
  color: #201E1F;
}
@media (max-width: 767px) {
  body {
    font-size: 1.6rem;
  }
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical {
  width: 1.7rem;
}

body::-webkit-scrollbar-button:increment, body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal {
  height: 1.7rem;
}

body::-webkit-scrollbar-thumb {
  background-color: #BB0C0C;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* FONT SIZING */
h1, h2, h3, h4, h5, h6, p, li, a {
  margin: 0rem;
  padding: 0rem;
  line-height: 1.1;
}

h1 {
  font-size: 6rem;
  font-family: "Raleway-Bold";
}
@media (max-width: 767px) {
  h1 {
    font-size: 4.5rem;
  }
}

h2 {
  font-size: 3rem;
  font-family: "Raleway-Bold";
}
@media (max-width: 767px) {
  h2 {
    font-size: 3rem;
  }
}

h3 {
  font-size: 2.4rem;
  font-family: "Raleway-Bold";
}
@media (max-width: 767px) {
  h3 {
    font-size: 2.4rem;
  }
}

h4 {
  font-size: 2.2rem;
  font-family: "Raleway-Regular";
}
@media (max-width: 767px) {
  h4 {
    font-size: 2.2rem;
  }
}

h5 {
  font-size: 2rem;
  font-family: "Raleway-Regular";
}
@media (max-width: 767px) {
  h5 {
    font-size: 2rem;
  }
}

h6 {
  font-size: 1.8rem;
  font-family: "Raleway-Regular";
}
@media (max-width: 767px) {
  h6 {
    font-size: 1.8rem;
  }
}

p {
  font-size: 1.6rem;
  font-family: "Raleway-Regular";
}
@media (max-width: 767px) {
  p {
    font-size: 1.4rem;
  }
}
@media (max-width: 380px) {
  p {
    font-size: 1.2rem;
  }
}
p strong {
  font-family: "Raleway-Bold";
}

a {
  font-size: 1.6rem;
  text-decoration: none;
}
@media (max-width: 767px) {
  a {
    font-size: 1.4rem;
  }
}
@media (max-width: 380px) {
  a {
    font-size: 1.2rem;
  }
}
a strong {
  font-family: "Raleway-Bold";
}
a:focus {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}

ul, ol {
  padding: 0rem;
  margin: 0rem;
}
ul li, ol li {
  font-size: 1.6rem;
}
@media (max-width: 767px) {
  ul li, ol li {
    font-size: 1.4rem;
  }
}
@media (max-width: 380px) {
  ul li, ol li {
    font-size: 1.2rem;
  }
}
ul li strong, ol li strong {
  font-family: "Raleway-Bold";
}

.center-recaptcha {
  padding-bottom: 1rem;
}
@media screen and (max-width: 991px) {
  .center-recaptcha {
    padding-bottom: 2rem;
  }
}

iframe {
  width: 100% !important;
}

.g-recaptcha {
  position: relative;
  width: 225px;
  height: 60px;
}
@media screen and (max-width: 767px) {
  .g-recaptcha {
    height: 55px;
  }
}

.g-recaptcha > :first-child {
  transform: scale(0.8);
  transform-origin: 0% 0;
}
@media screen and (max-width: 480px) {
  .g-recaptcha > :first-child {
    transform: scale(0.65);
  }
}

.reset-padding {
  padding-top: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
}

.center-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: auto;
}

@media screen and (max-width: 991px) {
  .center-btn-responsive {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1700;
  width: 100vw;
  height: 100vh;
  background-color: #201e1f;
}

.grid-section-controller {
  display: grid;
  grid-template-columns: 1fr;
}
.grid-section-controller .section-form.scrolled {
  grid-row: 7/8;
}

.cc_div {
  color: #7F050E !important;
}

.cc_div .cc-link {
  color: #7F050E !important;
  border-bottom: 0.1rem solid #7F050E !important;
}

#c-bns button:first-child, #s-bns button:first-child {
  background: #7F050E !important;
  color: #FFF !important;
}

.cc_div .c-bn {
  color: #7F050E !important;
}

#s-cnt .p {
  color: #201E1F !important;
}

.cc_div .act .b-bn .exp::before, .cc_div .b-bn .exp::before {
  border-color: #7F050E !important;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #7F050E !important;
}

#s-c-bn::after, #s-c-bn::before {
  background: #7F050E !important;
}

#s-c-bn::after, #s-c-bn::before {
  background: #7F050E !important;
}

/* Tooltips */
.tooltip {
  font-size: 1.6rem;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #FFF;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #FFF;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #FFF;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #FFF;
}

.tooltip-inner {
  color: #BB0C0C;
  background: #FFF;
  box-shadow: 0rem 0rem 1rem 0.3rem rgba(0, 0, 0, 0.3);
  padding: 1.5rem 1rem;
  line-height: 1.2;
}

/* BORDERS */
.align-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.border-servicios {
  width: 50%;
  border: 0.1rem solid #BB0C0C;
}

/* FONTS */
@font-face {
  font-family: "Raleway-Black";
  src: url("./../../build/fonts/Raleway-Black.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Bold";
  src: url("./../../build/fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("./../../build/fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "icomoon";
  src: url("./../../build/fonts/icomoon.eot?b342ug");
  src: url("./../../build/fonts/icomoon.eot?b342ug#iefix") format("embedded-opentype"), url("./../../build/fonts/icomoon.ttf?b342ug") format("truetype"), url("./../../build/fonts/icomoon.woff?b342ug") format("woff"), url("./../../build/fonts/icomoon.svg?b342ug#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
  content: "\e912";
}

.icon-alimentacion-bases-datos:before {
  content: "\e900";
}

.icon-almacenamiento:before {
  content: "\e901";
}

.icon-asesorias-contables:before {
  content: "\e902";
}

.icon-close:before {
  content: "\e903";
}

.icon-down:before {
  content: "\e904";
}

.icon-estibado-productos:before {
  content: "\e905";
}

.icon-facebook:before {
  content: "\e906";
}

.icon-left:before {
  content: "\e907";
}

.icon-linkedin:before {
  content: "\e908";
}

.icon-manejo-inventarios:before {
  content: "\e909";
}

.icon-maquila:before {
  content: "\e90a";
}

.icon-menu:before {
  content: "\e90b";
}

.icon-operacion-maquinas-montacarga:before {
  content: "\e90c";
}

.icon-picking-packing:before {
  content: "\e90d";
}

.icon-right:before {
  content: "\e90e";
}

.icon-servicios-generales:before {
  content: "\e90f";
}

.icon-telefono-main:before {
  content: "\e910";
}

.icon-telefono-second:before {
  content: "\e911";
}

.icon-up:before {
  content: "\e913";
}

.icon-vaceo:before {
  content: "\e914";
}

.header {
  background: rgba(255, 255, 255, 0.75);
  padding: 1rem 1.25rem 1rem 1.25rem;
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
}
@media (max-width: 1199px) {
  .header {
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
  }
}
@media (max-width: 767px) {
  .header {
    box-shadow: 0 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    background: #FFF;
    padding: 1rem 0rem 1rem 0rem;
  }
}
.header .navbar {
  padding: 0rem 0rem 0rem 0rem;
}
.header .navbar .navbar-brand span i:before {
  color: #FFF;
  font-size: 4rem;
}
@media (max-width: 767px) {
  .header .navbar .navbar-brand span i:before {
    color: #7F050E;
  }
}
.header .navbar .navbar-toggler {
  padding: 0;
  margin: 0;
  outline: none;
}
.header .navbar .navbar-toggler:focus {
  box-shadow: none;
}
.header .navbar .navbar-toggler span i:before {
  color: #FFF;
  font-size: 3.5rem;
}
@media (max-width: 767px) {
  .header .navbar .navbar-toggler span i:before {
    color: #7F050E;
  }
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse {
    border-top: 0.1rem solid #7F050E;
    margin-top: 1rem;
  }
}
.header .navbar .navbar-collapse .navbar-nav {
  width: 100%;
  gap: 0rem 4.5rem;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse .navbar-nav .nav-item {
    margin: 1.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
  border-bottom: 0.1rem solid #7F050E;
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
    border-bottom: 0.1rem solid #7F050E;
  }
}
.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #201E1F;
  padding: 0rem 0rem 0rem 0rem;
  border-bottom: 0.1rem solid transparent;
  font-family: "Raleway-Bold";
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #201E1F;
    font-family: "Raleway-Regular";
  }
}
.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
  border-bottom: 0.1rem solid #7F050E;
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:focus {
    border-bottom: 0.1rem solid #7F050E;
  }
}
.header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  border-bottom: 0.1rem solid #7F050E;
}
@media (max-width: 767px) {
  .header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
    border-bottom: 0.1rem solid #7F050E;
  }
}

/* MENU AGIL */
@keyframes pulse-scale {
  0% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1.1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.menu-agil {
  position: fixed;
  bottom: 15%;
  right: 1.5rem;
  z-index: 500;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
}
@media (max-width: 767px) {
  .menu-agil {
    bottom: 1rem;
    right: 1rem;
  }
}
.menu-agil:hover {
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1.1);
  animation: pulse-scale 2s infinite;
}
.menu-agil span i:before {
  display: block;
  background: #BB0C0C;
  color: #FFF;
  font-size: 5rem;
  padding: 2rem;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .menu-agil span i:before {
    font-size: 4rem;
    padding: 1.5rem;
  }
}

.menu-agil-body {
  position: fixed;
  bottom: 15%;
  right: 1.5rem;
  z-index: 600;
  width: 280px;
}
@media (max-width: 767px) {
  .menu-agil-body {
    width: 90%;
    bottom: 1rem;
    right: 0rem;
    left: 0rem;
    margin: 0 auto;
  }
}
.menu-agil-body .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 1.5rem;
}
.menu-agil-body .card-body {
  padding: 3rem 1.5rem 2rem 1.5rem;
  background: #BB0C0C;
  position: relative;
}
.menu-agil-body .card-body .close-menu {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
}
.menu-agil-body .card-body .close-menu i:before {
  color: #FFF;
  font-size: 2.4rem;
}
.menu-agil-body .card-body h4 {
  text-align: center;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 1rem 0rem;
  border-bottom: 0.1rem solid #FFF;
  color: #FFF;
}
.menu-agil-body .card-body ul {
  display: flex;
  flex-direction: column;
}
.menu-agil-body .card-body ul li {
  font-size: 1.6rem;
  color: #FFF;
  padding: 1.5rem 2rem 1.5rem 2rem;
}
.menu-agil-body .card-body ul li a {
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem 1rem;
}
.menu-agil-body .card-body ul li a span i:before {
  color: #FFF;
  font-size: 2.4rem;
}
.menu-agil-body .card-body ul li:hover {
  background: #EF1C22;
  color: #FFF;
  cursor: pointer;
}

.menu-servicios {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  z-index: 500;
}
.menu-servicios .content-block {
  padding: 3rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #EF1C22;
}
.menu-servicios .content-block .item-box span i:before {
  color: #FFF;
  font-size: 4rem;
  padding: 1rem;
}

#sliderMain.carousel .carousel-item {
  position: relative;
}
#sliderMain.carousel .carousel-item .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 65%;
  max-height: 37.5%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 400;
}
@media (max-width: 767px) {
  #sliderMain.carousel .carousel-item .item-box {
    max-width: 75%;
    max-height: 32.5%;
  }
}
#sliderMain.carousel .carousel-item h2 {
  color: #FFF;
  font-family: "Raleway-Regular";
  font-size: 4.5rem;
  text-transform: uppercase;
  padding: 1rem 3rem;
  text-align: center;
}
@media (max-width: 1199px) {
  #sliderMain.carousel .carousel-item h2 {
    font-size: 3rem;
  }
}
@media (max-width: 767px) {
  #sliderMain.carousel .carousel-item h2 {
    padding: 1rem 1.5rem;
    font-size: 3rem;
  }
}
#sliderMain.carousel .carousel-indicators {
  bottom: 12.5rem;
  margin-bottom: 0rem;
}
@media (max-width: 1920px) {
  #sliderMain.carousel .carousel-indicators {
    bottom: 14.5%;
  }
}
@media (max-width: 1199px) {
  #sliderMain.carousel .carousel-indicators {
    bottom: 12.5%;
  }
}
#sliderMain.carousel .carousel-indicators a {
  height: 0.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;
}
#sliderMain.carousel .carousel-indicators a.active {
  background-color: #EF1C22;
  width: 3rem;
}
#sliderMain.carousel .control-left {
  position: absolute;
  left: 5rem;
  bottom: 50%;
  cursor: pointer;
  opacity: 0.7;
  z-index: 500;
}
#sliderMain.carousel .control-left:hover {
  opacity: 1;
}
#sliderMain.carousel .control-left span i:before {
  color: #FFF;
  font-size: 6rem;
}
#sliderMain.carousel .control-right {
  position: absolute;
  right: 5rem;
  bottom: 50%;
  cursor: pointer;
  opacity: 0.7;
  z-index: 500;
}
#sliderMain.carousel .control-right:hover {
  opacity: 1;
}
#sliderMain.carousel .control-right span i:before {
  color: #FFF;
  font-size: 6rem;
}

#sliderAliados.carousel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 110px;
  gap: 0rem 1rem;
}
#sliderAliados.carousel .carousel-item img {
  padding: 0rem 0.75rem;
}
#sliderAliados.carousel .control-left {
  cursor: pointer;
  opacity: 0.7;
}
#sliderAliados.carousel .control-left:hover {
  opacity: 1;
}
#sliderAliados.carousel .control-left span i:before {
  color: #7F050E;
  font-size: 3rem;
}
#sliderAliados.carousel .control-right {
  cursor: pointer;
  opacity: 0.7;
}
#sliderAliados.carousel .control-right:hover {
  opacity: 1;
}
#sliderAliados.carousel .control-right span i:before {
  color: #7F050E;
  font-size: 3rem;
}

/*********************************************************************/
/* Bootstrap 5 Multiple Item Carousel */
/*********************************************************************/
#sliderAliados .carousel-item-next:not(.carousel-item-start),
#sliderAliados .active.carousel-item-end {
  transform: translateX(25%);
}

#sliderAliados .carousel-item-prev:not(.carousel-item-end),
#sliderAliados .active.carousel-item-start {
  transform: translateX(-25%);
}

@media (max-width: 767px) {
  #sliderAliados .carousel-item-next:not(.carousel-item-start),
#sliderAliados .active.carousel-item-end {
    transform: translateX(33.3%);
  }

  #sliderAliados .carousel-item-prev:not(.carousel-item-end),
#sliderAliados .active.carousel-item-start {
    transform: translateX(-33.3%);
  }
}
/* DEFECTO */
#sliderAliados .carousel-inner .carousel-item.active,
#sliderAliados .carousel-inner .carousel-item-next,
#sliderAliados .carousel-inner .carousel-item-prev {
  display: flex;
  align-items: center;
}

#sliderAliados .carousel-inner .carousel-item-right,
#sliderAliados .carousel-inner .carousel-item-left {
  transform: translateX(0);
}

.footer {
  background: #201E1F;
  padding: 4rem 0rem 4rem 0rem;
}
.footer .content-block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5rem;
}
@media (max-width: 767px) {
  .footer .content-block {
    grid-template-columns: 1fr;
    row-gap: 4rem;
  }
}
.footer .content-block .item-box p {
  color: #FFF;
  margin: 0rem 0rem 1.5rem 0rem;
}
.footer .content-block .item-box:nth-of-type(1) ul {
  border-left: 0.1rem solid #FFF;
  padding-left: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem 0rem;
}
@media (max-width: 767px) {
  .footer .content-block .item-box:nth-of-type(1) ul {
    border: none;
    padding-left: 0;
  }
}
.footer .content-block .item-box:nth-of-type(1) ul li {
  color: #FFF;
}
.footer .content-block .item-box:nth-of-type(1) ul li a {
  color: #FFF;
}
.footer .content-block .item-box:nth-of-type(1) ul li a:hover {
  color: #EF1C22;
}
.footer .content-block .item-box:nth-of-type(2) ul {
  display: flex;
  flex-direction: row;
  gap: 0rem 1.5rem;
}
.footer .content-block .item-box:nth-of-type(2) ul li {
  color: #FFF;
}
.footer .content-block .item-box:nth-of-type(2) ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0rem 1rem;
  color: #FFF;
}
.footer .content-block .item-box:nth-of-type(2) ul li a span i {
  font-size: 3rem;
}
.footer .content-block .item-box:nth-of-type(2) ul li a:hover {
  color: #EF1C22;
}
.footer .content-block .item-box:nth-of-type(2) ul:nth-of-type(1) {
  margin-bottom: 1.8rem;
}
.footer .content-block .item-box:nth-of-type(3) ul {
  display: flex;
  flex-direction: column;
  gap: 1.8rem 0rem;
}
.footer .content-block .item-box:nth-of-type(3) ul li {
  color: #FFF;
  text-align: right;
}
@media (max-width: 767px) {
  .footer .content-block .item-box:nth-of-type(3) ul li {
    text-align: left;
  }
}
.footer .content-block .item-box:nth-of-type(3) ul li a {
  color: #FFF;
}
.footer .content-block .item-box:nth-of-type(3) ul li a:hover {
  color: #EF1C22;
}

.btn-form {
  background: #FFF;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  width: 300px;
  color: #7F050E;
  font-size: 1.6rem;
  font-family: "Raleway-Bold";
  padding: 0.5rem 0.5rem;
}
.btn-form:hover {
  background: #EF1C22;
  color: #FFF;
}
@media (max-width: 767px) {
  .btn-form {
    width: 100%;
  }
}

.btn-cta-01 {
  background: #EF1C22;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  width: 300px;
  color: #FFF;
  font-size: 1.6rem;
  font-family: "Raleway-Bold";
  padding: 0.5rem 3.5rem;
  text-align: center;
}
.btn-cta-01:hover {
  background: #FFF;
  color: #7F050E;
}
@media (max-width: 767px) {
  .btn-cta-01 {
    padding: 0.7rem 2.5rem;
    width: 100%;
  }
}

.btn-cta-02 {
  background: #7F050E;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  color: #7F050E;
  font-size: 1.6rem;
  font-family: "Raleway-Bold";
  padding: 1.4rem 5rem;
  text-align: center;
  display: block;
}
.btn-cta-02 span {
  padding: 1rem 4rem;
  background: #FFF;
  border-radius: 2.5rem;
}
.btn-cta-02:hover {
  background: #FFF;
  color: #FFF;
}
.btn-cta-02:hover span {
  background: #7F050E;
}
@media (max-width: 767px) {
  .btn-cta-02 {
    padding: 1.2rem 0.5rem;
    width: 100%;
  }
  .btn-cta-02 span {
    padding: 0.7rem 1.5rem;
  }
}

.content-terminos-condiciones {
  overflow-y: scroll;
  max-height: 400px;
}
.content-terminos-condiciones .content {
  margin: 0rem 0rem 0.8rem 0rem;
}
.content-terminos-condiciones .content p.title {
  font-family: "Raleway-Bold";
  font-size: 1.4rem;
  text-transform: uppercase;
  margin: 0rem 0rem 2rem 0rem;
}
.content-terminos-condiciones .content p.subtitle {
  font-family: "Raleway-Regular";
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin: 0rem 0rem 1.6rem 0rem;
}
.content-terminos-condiciones .content p.body {
  font-family: "Raleway-Regular";
  font-size: 1.4rem;
  margin: 0rem 0rem 1.2rem 0rem;
}
.content-terminos-condiciones .content ol {
  list-style-position: inside;
  margin: 0rem 0rem 1.2rem 0rem;
}
.content-terminos-condiciones .content ol li {
  font-family: "Raleway-Regular";
  font-size: 1.4rem;
}
.content-terminos-condiciones .content ul {
  margin: 0rem 0rem 1.2rem 0rem;
}
.content-terminos-condiciones .content ul li {
  font-family: "Raleway-Regular";
  font-size: 1.4rem;
}

.modal-politica {
  z-index: 1800;
}
.modal-politica .modal-dialog {
  position: relative;
  max-width: 95%;
  margin-top: 0rem;
}
@media (max-width: 767px) {
  .modal-politica .modal-dialog {
    max-width: 90%;
    margin: 0rem auto 0rem auto;
  }
}
.modal-politica .modal-body {
  position: relative;
  padding: 0rem;
  padding: 7rem 2rem 1rem 2rem;
  max-height: 480px;
}
@media (max-width: 767px) {
  .modal-politica .modal-body {
    padding: 5rem 2rem 1rem 2rem;
  }
}
.modal-politica .modal-body .close-modal {
  position: absolute;
  right: 0;
  top: 2rem;
  z-index: 100;
  cursor: pointer;
}
@media (max-width: 767px) {
  .modal-politica .modal-body .close-modal {
    top: 1rem;
  }
}
.modal-politica .modal-body .close-modal span i:before {
  font-size: 3rem;
  color: #7F050E;
  padding: 2rem;
}
@media (max-width: 767px) {
  .modal-politica .modal-body .close-modal span i:before {
    padding: 1rem;
  }
}

.main {
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1250px) {
  .main {
    height: auto;
  }
}

.main.static {
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.section-aliados {
  padding: 8rem 0rem 8rem 0rem;
}
.section-aliados h3:nth-of-type(1) {
  color: #6A6A6A;
  text-align: center;
  margin: 0rem 0rem 3rem 0rem;
}

.section-form {
  padding: 0rem 0rem 7rem 0rem;
}
.section-form .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .section-form .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.section-form .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-form form {
  padding: 4rem 4.5rem 3.5rem 4.5rem;
  border-radius: 5rem;
  background: #7F050E;
  /* PRISTINE IMPLEMENTATION */
}
@media (max-width: 767px) {
  .section-form form {
    padding: 3rem 2.5rem 3.5rem 2rem;
  }
}
.section-form form h3:nth-of-type(1) {
  color: #FFF;
  text-align: center;
  margin: 0rem 0rem 3rem 0rem;
}
.section-form form .form-group {
  padding: 0rem 0rem 1rem 0rem;
}
.section-form form .form-group:last-of-type {
  padding: 0rem 0rem 0rem 0rem;
}
.section-form form .form-control {
  display: block;
  width: 100%;
  padding: 1.25rem 2rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  color: #EF1C22;
  background-color: #FFF;
  background-clip: padding-box;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (max-width: 767px) {
  .section-form form .form-control {
    border-radius: 1.5rem;
    padding: 0.75rem 1rem;
    font-size: 1.4rem;
  }
}
.section-form form .form-control.picker {
  background: url("./../../build/img/bg-select-departamento.webp"), #FFF;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 100.25% center;
  cursor: pointer;
}
.section-form form ::placeholder {
  color: #EF1C22;
}
.section-form form .checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  color: #FFF;
  gap: 0rem 1rem;
}
.section-form form .checkbox input {
  margin-bottom: 0.25rem;
}
.section-form form .checkbox span a {
  font-size: 1.6rem;
  color: #FFF;
}
.section-form form .checkbox span a:hover {
  color: #EF1C22;
}
.section-form form .form-contacto.collapse {
  margin-top: 0.5rem;
}
.section-form form .form-contacto.collapse .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  border-radius: 0.5rem;
}
.section-form form .form-contacto.collapse .card-body {
  padding: 0rem 0rem;
}
.section-form form .form-contacto.collapse .card-body ul {
  display: flex;
  flex-direction: column;
}
.section-form form .form-contacto.collapse .card-body ul li {
  font-size: 1.6rem;
  color: #7F050E;
  padding: 1.5rem 2rem 1.5rem 2rem;
}
.section-form form .form-contacto.collapse .card-body ul li:hover {
  background: #EF1C22;
  color: #FFF;
  cursor: pointer;
}
.section-form form .form-contacto.collapsing {
  margin-top: 0.5rem;
}
.section-form form .form-contacto.collapsing .card {
  border: none;
  border-radius: 0.5rem;
}
.section-form form .form-contacto.collapsing .card-body {
  padding: 0rem 0rem;
}
.section-form form .form-contacto.collapsing .card-body ul {
  display: flex;
  flex-direction: column;
}
.section-form form .form-contacto.collapsing .card-body ul li {
  font-size: 1.6rem;
  color: #7F050E;
  padding: 1.5rem 2rem 1.5rem 2rem;
}
.section-form form .pristine-error.text-help {
  font-size: 1.4rem;
  color: #FFF;
  margin: 0.5rem 0rem 0rem 1rem;
}
.section-form form .input-error {
  font-size: 1.4rem;
  color: #FFF;
  margin: 0.5rem 0rem 0rem 1rem;
}

.section-form.home {
  padding: 0rem 0rem 7rem 0rem;
}
.section-form.home .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .section-form.home .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.section-form.home .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section-form.servicios {
  padding: 0rem 0rem 7rem 0rem;
  position: relative;
  margin-top: -5rem;
}
.section-form.servicios .content-block {
  display: block;
}
.section-form.servicios .content-block .item-box {
  display: block;
}
.section-form.servicios form {
  max-width: 652px;
  margin: 0 auto;
}

.section-form.trabaja-con-nosotros {
  padding: 0rem 0rem 7rem 0rem;
  position: relative;
  margin-top: -5rem;
}
@media (max-width: 767px) {
  .section-form.trabaja-con-nosotros {
    position: static;
    margin-top: 3rem;
  }
}
.section-form.trabaja-con-nosotros .content-block {
  display: block;
}
.section-form.trabaja-con-nosotros .content-block .item-box {
  display: block;
}
.section-form.trabaja-con-nosotros form {
  max-width: 652px;
  margin: 0 auto;
}

.section-form.contactanos {
  padding: 0rem 0rem 0rem 0rem;
  position: relative;
  margin-top: -5rem;
}
@media (max-width: 767px) {
  .section-form.contactanos {
    padding: 5rem 0rem 7rem 0rem;
    margin-top: 0rem;
    position: static;
  }
}
.section-form.contactanos .content-block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .section-form.contactanos .content-block {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}
.section-form.contactanos .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) {
  padding-right: 5rem;
}
@media (max-width: 767px) {
  .section-form.contactanos .content-block .item-box:nth-last-of-type(2) {
    padding-right: 0rem;
  }
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem 0rem;
  padding-bottom: 2.5rem;
  border-bottom: 0.1rem solid #7F050E;
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) ul li {
  color: #7F050E;
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) ul li a {
  color: #7F050E;
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) img:nth-of-type(1) {
  margin: 3rem 0rem 2rem 0rem;
  max-width: 176px;
}
.section-form.contactanos .content-block .item-box:nth-last-of-type(2) p:nth-of-type(1) {
  text-align: justify;
}

@keyframes transicion-envio {
  0% {
    transform: scale(initial);
  }
  20% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.25);
    opacity: 1;
  }
  90% {
    transform: scale(0.25);
  }
  100% {
    opacity: 0;
  }
}
.animacion-form {
  animation-name: transicion-envio;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  opacity: 1;
}

@keyframes opacidad-contenido {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.suavizar-opacidad {
  animation-name: opacidad-contenido;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.spinner-border.loader-form {
  color: #7F050E;
  width: 60px;
  height: 60px;
  margin-top: 50px;
}

@media screen and (max-width: 575px) {
  .spinner-border.loader-form {
    margin-top: 35px;
  }
}
.tx-seccion-form-send {
  color: #7F050E;
  font-family: "Raleway-Regular";
  font-size: 2.2rem;
  text-align: center;
  margin: 20px 0px 20px 0px;
}
@media screen and (max-width: 767px) {
  .tx-seccion-form-send {
    font-size: 2.2rem;
  }
}

.renew-lead {
  padding: 4rem 4.5rem 3.5rem 4.5rem;
  border-radius: 5rem;
  background: #7F050E;
}
@media (max-width: 767px) {
  .renew-lead {
    padding: 3rem 2.5rem 3.5rem 2rem;
  }
}
.renew-lead p {
  color: #FFF;
  font-family: "Raleway-Regular";
  font-size: 2rem;
  text-align: center;
  margin: 0px 0px 20px 0px;
}
@media screen and (max-width: 767px) {
  .renew-lead p {
    font-size: 2rem;
  }
}
.renew-lead p:last-of-type {
  margin: 0px 0px 30px 0px;
}
.renew-lead a:hover {
  cursor: pointer;
}

.section-01 {
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 991px) {
  .section-01 {
    position: static;
  }
}
.section-01 .content-block {
  border-radius: 6rem 6rem 0rem 0rem;
  background: url("./../../build/img/bg-home-section-01.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  padding: 5rem 5rem 4rem 5rem;
  display: grid;
  grid-template-columns: 40% 60%;
}
@media (max-width: 1199px) {
  .section-01 .content-block {
    background: url("./../../build/img/bg-home-lg-section-01.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
}
@media (max-width: 991px) {
  .section-01 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 991px) {
  .section-01 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-01 .content-block .item-box h2:nth-of-type(1) {
  color: #201E1F;
  font-family: "Raleway-Regular";
  margin: 0rem 0rem 1rem 0rem;
}
.section-01 .content-block .item-box h3:nth-of-type(1) {
  font-family: "Raleway-Regular";
  font-size: 1.4rem;
  color: #7F050E;
  text-transform: uppercase;
  border-bottom: 0.1rem solid #7F050E;
  padding: 0rem 0rem 1.5rem 0rem;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-01 .content-block .item-box p:nth-of-type(1) {
  color: #201E1F;
}

.section-02 {
  padding: 6rem 0rem 0rem 0rem;
}
.section-02 h3:nth-of-type(1) {
  color: #6A6A6A;
  text-align: center;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-02 .content-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  row-gap: 1.5rem;
  padding: 3rem 6rem 8rem 16.6%;
  background: url("../../../build/img/bg-home-section-02.webp");
  background-repeat: no-repeat;
  background-size: 150px auto;
  background-position: bottom left;
}
@media (max-width: 1199px) {
  .section-02 .content-block {
    padding: 3rem 2.5rem 8rem 2.5rem;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 1.5rem;
    background: none;
  }
}
@media (max-width: 767px) {
  .section-02 .content-block {
    padding: 3rem 0rem 8rem 0rem;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.5rem;
  }
}
@media (max-width: 575px) {
  .section-02 .content-block {
    grid-template-columns: 1fr;
    column-gap: 0.7rem;
    row-gap: 0.7rem;
  }
}
.section-02 .content-block .item-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1.5rem 1rem 3rem 1rem;
  border-radius: 3rem 3rem 0rem 0rem;
  background: #FFF;
  border: 0.1rem solid #7F050E;
  border-radius: 2.5rem;
}
@media (max-width: 575px) {
  .section-02 .content-block .item-box {
    padding: 1rem 0.5rem 2rem 0.5rem;
  }
}
.section-02 .content-block .item-box span:nth-of-type(1) i:before {
  font-size: 11rem;
  color: #7F050E;
}
.section-02 .content-block .item-box h5:nth-of-type(1) {
  margin: 0rem 0rem 2.5rem 0rem;
  color: #7F050E;
}
.section-02 .content-block .item-box p:nth-of-type(1) {
  margin: 0rem 0rem 2.5rem 0rem;
  color: #7F050E;
}
.section-02 .content-block .item-box a:nth-of-type(1) {
  color: #7F050E;
  font-family: "Raleway-Bold";
}
.section-02 .content-block .item-box a:nth-of-type(1):hover {
  color: #7F050E;
}
.section-02 .content-block .item-box:nth-of-type(1):hover {
  background: url("./../../build/img/home/card-servicios-01.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(2):hover {
  background: url("./../../build/img/home/card-servicios-02.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(3):hover {
  background: url("./../../build/img/home/card-servicios-03.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(4):hover {
  background: url("./../../build/img/home/card-servicios-04.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(5):hover {
  background: url("./../../build/img/home/card-servicios-05.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(6):hover {
  background: url("./../../build/img/home/card-servicios-06.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(7):hover {
  background: url("./../../build/img/home/card-servicios-07.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(8):hover {
  background: url("./../../build/img/home/card-servicios-08.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(9):hover {
  background: url("./../../build/img/home/card-servicios-09.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.section-02 .content-block .item-box:nth-of-type(10):hover {
  background: url("./../../build/img/home/card-servicios-10.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.section-03 {
  padding: 3rem 0rem 3rem 0rem;
  background: linear-gradient(90deg, #201e1f 0%, #201e1f 100%), url("./../../build/img/bg-home-section-03.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: hard-light;
}
@media (max-width: 767px) {
  .section-03 {
    background: linear-gradient(90deg, #201e1f 0%, #201e1f 100%), url("./../../build/img/bg-home-mobile-section-03.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}
.section-03 h3:nth-of-type(1) {
  color: #FFF;
  text-align: center;
  margin: 0rem 0rem 4.5rem 0rem;
}
.section-03 .content-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 767px) {
  .section-03 .content-block {
    flex-direction: column;
  }
}
.section-03 .content-block p {
  color: #FFF;
}
@media (max-width: 767px) {
  .section-03 .content-block p {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.section-nosotros-01 {
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-nosotros-01 {
    position: static;
  }
}
.section-nosotros-01 .content-block {
  background: #F2F2F2;
  border-radius: 6rem 6rem 0rem 0rem;
  padding: 7rem 16.6% 8rem 16.6%;
}
@media (max-width: 767px) {
  .section-nosotros-01 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-nosotros-01 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-nosotros-01 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
  text-align: center;
}
.section-nosotros-01 .content-block .item-box span {
  display: block;
  margin: 0rem 0rem 2rem 0rem;
}
.section-nosotros-01 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 5rem;
}
.section-nosotros-01 .content-block .item-box h3:nth-of-type(2) {
  color: #7F050E;
  font-family: "Raleway-Regular";
  margin: 0rem 0rem 1rem 0rem;
}
.section-nosotros-01 .content-block .item-box p:nth-of-type(1) {
  color: #7F050E;
  border-bottom: 0.1rem solid #7F050E;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 2rem 0rem;
  letter-spacing: 0.2rem;
}
.section-nosotros-01 .content-block .item-box p:nth-of-type(2) {
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-nosotros-01 .content-block .item-box p:nth-of-type(3) {
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}

.section-nosotros-02 {
  padding: 7rem 2.5rem 5rem 2.5rem;
  background: url("./../../build/img/bg-nosotros-section-02.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-nosotros-02 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 3rem 0rem;
}

.section-nosotros-03 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-nosotros-03 {
    position: static;
  }
}
.section-nosotros-03 .content-block {
  background: #F2F2F2;
  padding: 7rem 0rem 4rem 16.6%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.5rem;
  border-bottom: 0.1rem solid #7F050E;
}
@media (max-width: 767px) {
  .section-nosotros-03 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
.section-nosotros-03 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}
@media (max-width: 767px) {
  .section-nosotros-03 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-nosotros-03 .content-block .item-box h3:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 3.5rem 0rem;
}
.section-nosotros-03 .content-block .item-box p:nth-of-type(1) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}

.section-nosotros-04 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-nosotros-04 {
    position: static;
  }
}
.section-nosotros-04 .content-block {
  background: #F2F2F2;
  padding: 7rem 16.6% 4rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3.5rem;
  border-bottom: 0.1rem solid #7F050E;
}
@media (max-width: 767px) {
  .section-nosotros-04 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
.section-nosotros-04 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}
@media (max-width: 767px) {
  .section-nosotros-04 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-nosotros-04 .content-block .item-box h3:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 3.5rem 0rem;
  text-align: right;
}
@media (max-width: 767px) {
  .section-nosotros-04 .content-block .item-box h3:nth-of-type(1) {
    text-align: left;
  }
}
.section-nosotros-04 .content-block .item-box p:nth-of-type(1) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
@media (max-width: 767px) {
  .section-nosotros-04 .content-block .item-box:nth-of-type(2) {
    grid-row: 1/2;
  }
}

.section-nosotros-05 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-nosotros-05 {
    position: static;
  }
}
.section-nosotros-05 .content-block {
  background: #F2F2F2;
  padding: 7rem 16.6% 8rem 16.6%;
}
@media (max-width: 767px) {
  .section-nosotros-05 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-nosotros-05 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-nosotros-05 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
  text-align: center;
}
.section-nosotros-05 .content-block .item-box span {
  display: block;
  margin: 0rem 0rem 2rem 0rem;
}
.section-nosotros-05 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 5rem;
}
.section-nosotros-05 .content-block .item-box h3:nth-of-type(2) {
  color: #7F050E;
  font-family: "Raleway-Regular";
  margin: 0rem 0rem 1rem 0rem;
}
.section-nosotros-05 .content-block .item-box p:nth-of-type(1) {
  color: #7F050E;
  border-bottom: 0.1rem solid #7F050E;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 2rem 0rem;
  letter-spacing: 0.2rem;
}
.section-nosotros-05 .content-block .item-box p:nth-of-type(2) {
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-nosotros-05 .content-block .item-box p:nth-of-type(3) {
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}

.section-servicios-01 {
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-servicios-01 {
    position: static;
  }
}
.section-servicios-01 .content-block {
  background: #F2F2F2;
  border-radius: 6rem 6rem 0rem 0rem;
  padding: 7rem 16.6% 4rem 16.6%;
}
@media (max-width: 767px) {
  .section-servicios-01 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-01 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-servicios-01 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
  text-align: center;
}
.section-servicios-01 .content-block .item-box h3:nth-of-type(2) {
  color: #7F050E;
  font-family: "Raleway-Regular";
  margin: 0rem 0rem 1rem 0rem;
}
.section-servicios-01 .content-block .item-box p:nth-of-type(1) {
  color: #7F050E;
  border-bottom: 0.1rem solid #7F050E;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 2rem 0rem;
  letter-spacing: 0.2rem;
}
.section-servicios-01 .content-block .item-box p:nth-of-type(2) {
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}

.section-servicios-separador-01 {
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  background: url("./../../build/img/bg-separador-servicios-01.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-servicios-separador-01 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 3rem 0rem;
}

.section-servicios-separador-02 {
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  background: url("./../../build/img/bg-separador-servicios-02.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-servicios-separador-02 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 3rem 0rem;
}

.section-servicios-separador-03 {
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  background: url("./../../build/img/bg-separador-servicios-03.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-servicios-separador-03 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 3rem 0rem;
}

@media (max-width: 767px) {
  .section-servicios-03 {
    position: static;
  }
}
.section-servicios-03 .bg {
  background: url("../../../build/img/bg-servicios-section-03.webp"), #F2F2F2;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 11% auto;
}
@media (max-width: 767px) {
  .section-servicios-03 .bg {
    background: none;
  }
}
.section-servicios-03 .bg h3:nth-of-type(1) {
  padding: 4rem 0rem 0rem 0rem;
  color: #6A6A6A;
  text-align: center;
}
.section-servicios-03 .content-block {
  padding: 10rem 16.6% 6rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    padding: 4rem 0rem 2.5rem 0rem;
  }
}
.section-servicios-03 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-servicios-03 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 10rem;
}
@media (max-width: 1199px) {
  .section-servicios-03 .content-block .item-box span i:before {
    font-size: 7rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block .item-box span i:before {
    font-size: 10rem;
  }
}
@media (max-width: 1199px) {
  .section-servicios-03 .content-block .item-box span {
    width: 6.5rem;
    height: 6.5rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block .item-box span {
    width: 9.5rem;
    height: 9.5rem;
  }
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
  color: #201E1F;
  margin: 0rem 0rem 3rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #7F050E;
  margin: 0rem 0rem 1.5rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(3) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(3) {
    font-size: 1rem;
  }
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(4) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(5) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(6) {
  color: #201E1F;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box ul:nth-of-type(1) {
  margin: 0rem 0rem 1.5rem 1.5rem;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box p:nth-of-type(7) {
  color: #201E1F;
  margin: 0rem 0rem 1.5rem 0rem;
}
.section-servicios-03 .content-block:nth-of-type(1) .item-box ul:nth-of-type(2) {
  margin: 0rem 0rem 0rem 1.5rem;
}
.section-servicios-03 .content-block:nth-of-type(3) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-03 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-03 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-03 .content-block:nth-of-type(3) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(3) .item-box p:nth-of-type(3) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-03 .content-block:nth-of-type(3) .item-box p:nth-of-type(4) {
  color: #201E1F;
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}

@media (max-width: 767px) {
  .section-servicios-04 {
    position: static;
  }
}
.section-servicios-04 .bg {
  background: url("../../../build/img/bg-servicios-section-04.webp"), #F2F2F2;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 11% auto;
}
@media (max-width: 767px) {
  .section-servicios-04 .bg {
    background: none;
  }
}
.section-servicios-04 .content-block {
  padding: 10rem 16.6% 6rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    padding: 4rem 0rem 2.5rem 0rem;
  }
}
.section-servicios-04 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-servicios-04 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 10rem;
}
@media (max-width: 1199px) {
  .section-servicios-04 .content-block .item-box span i:before {
    font-size: 7rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block .item-box span i:before {
    font-size: 10rem;
  }
}
@media (max-width: 1199px) {
  .section-servicios-04 .content-block .item-box span {
    width: 6.5rem;
    height: 6.5rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block .item-box span {
    width: 9.5rem;
    height: 9.5rem;
  }
}
.section-servicios-04 .content-block:nth-of-type(1) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-04 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-04 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-04 .content-block:nth-of-type(1) .item-box p:nth-of-type(3) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-04 .content-block:nth-of-type(1) .item-box p:nth-of-type(4) {
  color: #201E1F;
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}
.section-servicios-04 .content-block:nth-of-type(3) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-04 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-04 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-04 .content-block:nth-of-type(3) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-04 .content-block:nth-of-type(3) .item-box p:nth-of-type(3) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}

@media (max-width: 767px) {
  .section-servicios-05 {
    position: static;
  }
}
.section-servicios-05 .bg {
  background: url("../../../build/img/bg-servicios-section-05.webp"), #F2F2F2;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 11% auto;
}
@media (max-width: 767px) {
  .section-servicios-05 .bg {
    background: none;
  }
}
.section-servicios-05 .bg h3:nth-of-type(1) {
  padding: 4rem 0rem 0rem 0rem;
  color: #6A6A6A;
  text-align: center;
}
.section-servicios-05 .content-block {
  padding: 10rem 16.6% 6rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    padding: 4rem 0rem 2.5rem 0rem;
  }
}
.section-servicios-05 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-servicios-05 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 10rem;
}
@media (max-width: 1199px) {
  .section-servicios-05 .content-block .item-box span i:before {
    font-size: 7rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block .item-box span i:before {
    font-size: 10rem;
  }
}
@media (max-width: 1199px) {
  .section-servicios-05 .content-block .item-box span {
    width: 6.5rem;
    height: 6.5rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block .item-box span {
    width: 9.5rem;
    height: 9.5rem;
  }
}
.section-servicios-05 .content-block:nth-of-type(1) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-05 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-05 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-05 .content-block:nth-of-type(3) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-05 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-05 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-05 .content-block:nth-of-type(3) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}

@media (max-width: 767px) {
  .section-servicios-06 {
    position: static;
  }
}
.section-servicios-06 .bg {
  background: url("../../../build/img/bg-servicios-section-06.webp"), #F2F2F2;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 11% auto;
}
@media (max-width: 767px) {
  .section-servicios-06 .bg {
    background: none;
  }
}
.section-servicios-06 .content-block {
  padding: 10rem 16.6% 6rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    padding: 4rem 0rem 2.5rem 0rem;
  }
}
.section-servicios-06 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-servicios-06 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 10rem;
}
@media (max-width: 1199px) {
  .section-servicios-06 .content-block .item-box span i:before {
    font-size: 7rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block .item-box span i:before {
    font-size: 10rem;
  }
}
@media (max-width: 1199px) {
  .section-servicios-06 .content-block .item-box span {
    width: 6.5rem;
    height: 6.5rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block .item-box span {
    width: 9.5rem;
    height: 9.5rem;
  }
}
.section-servicios-06 .content-block:nth-of-type(1) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-06 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-06 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-06 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}
.section-servicios-06 .content-block:nth-of-type(3) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-06 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-06 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-06 .content-block:nth-of-type(3) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-06 .content-block:nth-of-type(3) .item-box p:nth-of-type(3) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-06 .content-block:nth-of-type(3) .item-box ul:nth-of-type(1) {
  margin: 0rem 0rem 1.5rem 1.5rem;
}

@media (max-width: 767px) {
  .section-servicios-07 {
    position: static;
  }
}
.section-servicios-07 .bg {
  background: url("../../../build/img/bg-servicios-section-07.webp"), #F2F2F2;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 11% auto;
}
@media (max-width: 767px) {
  .section-servicios-07 .bg {
    background: none;
  }
}
.section-servicios-07 .bg h3:nth-of-type(1) {
  padding: 4rem 0rem 0rem 0rem;
  color: #6A6A6A;
  text-align: center;
}
.section-servicios-07 .content-block {
  padding: 10rem 16.6% 6rem 0rem;
  display: grid;
  grid-template-columns: 20% 80%;
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block {
    grid-template-columns: 1fr;
    row-gap: 2.5rem;
    padding: 4rem 0rem 2.5rem 0rem;
  }
}
.section-servicios-07 .content-block .item-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block .item-box {
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
}
.section-servicios-07 .content-block .item-box span i:before {
  color: #7F050E;
  font-size: 10rem;
}
@media (max-width: 1199px) {
  .section-servicios-07 .content-block .item-box span i:before {
    font-size: 7rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block .item-box span i:before {
    font-size: 10rem;
  }
}
@media (max-width: 1199px) {
  .section-servicios-07 .content-block .item-box span {
    width: 6.5rem;
    height: 6.5rem;
  }
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block .item-box span {
    width: 9.5rem;
    height: 9.5rem;
  }
}
.section-servicios-07 .content-block:nth-of-type(1) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-07 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block:nth-of-type(1) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-07 .content-block:nth-of-type(1) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box h4:nth-of-type(1) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(1) {
    font-size: 1rem;
  }
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(2) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(3) {
  color: #7F050E;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(4) {
  color: #7F050E;
  font-size: 1.2rem;
  margin: 0rem 0rem 3.5rem 0rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}
@media (max-width: 767px) {
  .section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(4) {
    font-size: 1rem;
  }
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(5) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(6) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box p:nth-of-type(7) {
  color: #201E1F;
  margin: 0rem 0rem 2rem 0rem;
  text-align: justify;
}
.section-servicios-07 .content-block:nth-of-type(3) .item-box ul:nth-of-type(1) {
  margin: 0rem 0rem 1.5rem 1.5rem;
}

.section-servicios-08 {
  padding: 8rem 2.5rem 10rem 2.5rem;
  background: url("./../../build/img/bg-servicios-section-08.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-servicios-08 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 0rem 0rem;
}

.section-trabaja-con-nosotros-01 {
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-trabaja-con-nosotros-01 {
    position: static;
  }
}
.section-trabaja-con-nosotros-01 .content-block {
  background: #F2F2F2;
  border-radius: 6rem 6rem 0rem 0rem;
  padding: 7rem 16.6% 5rem 16.6%;
}
@media (max-width: 767px) {
  .section-trabaja-con-nosotros-01 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-trabaja-con-nosotros-01 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-trabaja-con-nosotros-01 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
  padding: 0rem;
  text-align: center;
}
.section-trabaja-con-nosotros-01 .content-block .item-box h3:nth-of-type(2) {
  color: #7F050E;
  font-family: "Raleway-Regular";
  border-bottom: 0.1rem solid #7F050E;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 1rem 0rem;
}
.section-trabaja-con-nosotros-01 .content-block .item-box p:nth-of-type(1) {
  margin: 0rem 0rem 4rem 0rem;
  text-align: justify;
}
.section-trabaja-con-nosotros-01 .content-block .item-box p:nth-of-type(2) {
  margin: 0rem 0rem 0rem 0rem;
  color: #7F050E;
  text-transform: uppercase;
  text-align: justify;
  letter-spacing: 0.2rem;
}

.section-trabaja-con-nosotros-02 {
  padding: 0rem 0rem 7rem 0rem;
}
.section-trabaja-con-nosotros-02 .content-block {
  padding: 7rem 2.5rem 12rem 2.5rem;
  background: url("./../../build/img/bg-trabaja-con-nosotros-section-02.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-trabaja-con-nosotros-02 .content-block .item-box img:nth-of-type(1) {
  margin: 0rem auto 3rem auto;
}

.section-contactanos-01 {
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-contactanos-01 {
    position: static;
  }
}
.section-contactanos-01 .content-block {
  background: #F2F2F2;
  border-radius: 6rem 6rem 0rem 0rem;
  padding: 7rem 16.6% 4rem 16.6%;
}
@media (max-width: 767px) {
  .section-contactanos-01 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-contactanos-01 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-contactanos-01 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
  text-align: center;
}
.section-contactanos-01 .content-block .item-box h3:nth-of-type(2) {
  color: #7F050E;
  font-family: "Raleway-Regular";
  margin: 0rem 0rem 1rem 0rem;
}
.section-contactanos-01 .content-block .item-box p:nth-of-type(1) {
  color: #7F050E;
  border-bottom: 0.1rem solid #7F050E;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 2rem 0rem;
  letter-spacing: 0.2rem;
}
.section-contactanos-01 .content-block .item-box p:nth-of-type(2) {
  margin: 0rem 0rem 0rem 0rem;
  text-align: justify;
}

.section-contactanos-02 {
  padding: 4.5rem 2.5rem 4.5rem 2.5rem;
  background: url("./../../build/img/bg-contactanos-section-02.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-contactanos-02 .content-block {
  background: #F2F2F2;
}
.section-contactanos-02 .content-block .item-box h3:nth-of-type(1) {
  text-align: center;
  color: #7F050E;
  font-weight: "Raleway-Regular";
  margin: 0rem 0rem 3rem 0rem;
}

.section-contactanos-04 {
  padding: 0rem 0rem 0rem 0rem;
}
@media (max-width: 767px) {
  .section-contactanos-04 {
    position: static;
  }
}
.section-contactanos-04 .content-block {
  background: #F2F2F2;
  padding: 7rem 16.6% 4rem 16.6%;
}
@media (max-width: 767px) {
  .section-contactanos-04 .content-block {
    background: #FFF;
    grid-template-columns: 1fr;
    padding: 5rem 0rem 4rem 0rem;
  }
}
@media (max-width: 767px) {
  .section-contactanos-04 .content-block .item-box {
    padding: 4rem 2.5rem 2rem 2.5rem;
  }
}
.section-contactanos-04 .content-block .item-box h3:nth-of-type(1) {
  color: #6A6A6A;
  margin: 0rem 0rem 3.5rem 0rem;
}
.section-contactanos-04 .content-block .item-box p:nth-of-type(1) {
  color: #7F050E;
  border-bottom: 0.1rem solid #7F050E;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0rem 0rem 1rem 0rem;
  margin: 0rem 0rem 2rem 0rem;
  letter-spacing: 0.2rem;
}
.section-contactanos-04 .content-block .item-box:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.5rem;
  row-gap: 1.5rem;
}
@media (max-width: 767px) {
  .section-contactanos-04 .content-block .item-box:nth-of-type(2) {
    grid-template-columns: 1fr;
  }
}
.section-contactanos-04 .content-block .item-box:nth-of-type(2) .box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}