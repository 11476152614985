@use 'base/variables' as v;
@use 'base/mixins' as m;

.menu-servicios{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  z-index: 500;
  .content-block{
    padding: 3rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: v.$base-03;
    .item-box{
      span{
        i:before{
          color: v.$blanco; 
          font-size: 4rem;
          padding: 1rem;
        }
      }
    }
  }
}