@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-02{
  padding: 6rem 0rem 0rem 0rem;
  h3:nth-of-type(1){
    color: v.$gris-02;
    text-align: center;
    margin: 0rem 0rem 1.5rem 0rem;
  }
  .content-block{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    row-gap: 1.5rem;
    // padding: 0rem 2.5rem 0rem 2.5rem;

    padding: 3rem 6rem 8rem 16.6%;
    
    background: url('../../../build/img/bg-home-section-02.webp');
    background-repeat: no-repeat;
    background-size: 150px auto;
    background-position: bottom left;
    @include m.mq-lg{
      padding: 3rem 2.5rem 8rem 2.5rem;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 1.5rem;
      background: none;
    }
    @include m.mq-sm{
      padding: 3rem 0rem 8rem 0rem;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1.5rem;
    }
    @include m.mq-xs{
      grid-template-columns: 1fr;
      column-gap: .7rem;
      row-gap: .7rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: flex-end;
      justify-content: space-between;
      text-align: center;
      padding: 1.5rem 1rem 3rem 1rem;
      border-radius: 3rem 3rem 0rem 0rem;
      // background: v.$gris-01;
      background: v.$blanco;

      border: .1rem solid v.$base-01;
      border-radius: 2.5rem;
      @include m.mq-xs{
        padding: 1rem .5rem 2rem .5rem;
      }
      span:nth-of-type(1){
        i:before{
          font-size: 11rem;
          color: v.$base-01;
        }
      }
      h5:nth-of-type(1){
        margin: 0rem 0rem 2.5rem 0rem;
        color: v.$base-01;
      }
      p:nth-of-type(1){
        margin: 0rem 0rem 2.5rem 0rem;
        color: v.$base-01;
      }
      a:nth-of-type(1){
        color: v.$base-01;
        font-family: v.$font-bold-01;
        &:hover{
          // color: v.$base-03;
          color: v.$base-01;
        }
      }
    }
    .item-box:nth-of-type(1){
      &:hover{
        background: url('./../../build/img/home/card-servicios-01.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(2){
      &:hover{
        background: url('./../../build/img/home/card-servicios-02.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(3){
      &:hover{
        background: url('./../../build/img/home/card-servicios-03.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(4){
      &:hover{
        background: url('./../../build/img/home/card-servicios-04.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(5){
      &:hover{
        background: url('./../../build/img/home/card-servicios-05.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(6){
      &:hover{
        background: url('./../../build/img/home/card-servicios-06.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(7){
      &:hover{
        background: url('./../../build/img/home/card-servicios-07.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(8){
      &:hover{
        background: url('./../../build/img/home/card-servicios-08.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(9){
      &:hover{
        background: url('./../../build/img/home/card-servicios-09.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
    .item-box:nth-of-type(10){
      &:hover{
        background: url('./../../build/img/home/card-servicios-10.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
      }
    }
  }
}