@use 'base/variables' as v;
@use 'base/mixins' as m;

.btn-form{
  // background: url('./../../build/img/bg-btn-layout-01.svg'), v.$blanco;
  background: v.$blanco;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  width: 300px;
  color: v.$base-01;
  font-size: v.$body-03;
  font-family: v.$font-bold-01;
  padding: .5rem .5rem;
  &:hover{
    background: v.$base-03;
    color: v.$blanco;
  }
  @include m.mq-sm{
    width: 100%;
  }
}

.btn-cta-01{
  background: v.$base-03;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  width: 300px;
  color: v.$blanco;
  font-size: v.$body-03;
  font-family: v.$font-bold-01;
  padding: .5rem 3.5rem;
  text-align: center;
  &:hover{
    background: v.$blanco;
    color: v.$base-01;
  }
  @include m.mq-sm{
    padding: .7rem 2.5rem;
    width: 100%;
  }
}

.btn-cta-02{
  background: v.$base-01;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  border-radius: 2.5rem;
  border: none;
  color: v.$base-01;
  font-size: v.$body-03;
  font-family: v.$font-bold-01;
  padding: 1.4rem 5rem;
  text-align: center;
  display: block;
  span{
    padding: 1rem 4rem;
    background: v.$blanco;
    border-radius: 2.5rem;
  }
  &:hover{
    background: v.$blanco;
    color: v.$blanco;
    span{
      background: v.$base-01;
    }
  }
  @include m.mq-sm{
    padding: 1.2rem .5rem;
    width: 100%;
    span{
      padding: .7rem 1.5rem;
    }
  }
}