/* MENU AGIL */
@use 'base/variables' as v;
@use 'base/mixins' as m;

@keyframes pulse-scale {
	0% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.menu-agil{
  position: fixed;
  bottom: 15%;
  right: 1.5rem;
  z-index: 500;

  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  opacity: 1;
  transform: scale(1);
  animation: pulse 2s infinite;
  @include m.mq-sm{
    bottom: 1rem;
    right: 1rem;
  }
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    opacity: 1;
    transform: scale(1.1);
    animation: pulse-scale 2s infinite;
  }
  span{
    i:before{
      display: block;
      // background: v.$base-01;
      background: v.$base-02;
      color: v.$blanco;
      font-size: 5rem;
      padding: 2rem;
      border-radius: 50%;
      @include m.mq-sm{
        font-size: 4rem;
        padding: 1.5rem;
      }
    }
  }
}

.menu-agil-body{
  position: fixed;
  bottom: 15%;
  right: 1.5rem;
  z-index: 600;
  width: 280px;
  @include m.mq-sm{
    width: 90%;
    bottom: 1rem;
    right: 0rem;
    left: 0rem;
    margin: 0 auto;
  }
  .card{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 1.5rem;
  }
  .card-body{
    padding: 3rem 1.5rem 2rem 1.5rem;
    // background: v.$base-01;
    background: v.$base-02;
    position: relative;
    .close-menu{
      position: absolute;
      right: 1.5rem;
      top:1.5rem;
      cursor: pointer;
      i:before{
        color: v.$blanco;
        font-size: v.$h3-xl;
      }
    }
    h4{
      text-align: center;
      padding: 0rem 0rem 1rem 0rem;
      margin: 0rem 0rem 1rem 0rem;
      border-bottom: .1rem solid v.$blanco;
      color: v.$blanco;
    }
    ul{
      display: flex;
      flex-direction: column;
      li{
        font-size: v.$body-03;
        color: v.$blanco;
        padding: 1.5rem 2rem 1.5rem 2rem;
        a{
          color: v.$blanco;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap:0rem 1rem;
          span{
            i:before{
              color: v.$blanco;
              font-size: v.$h3-sm;
            }
          }
        }
      }
      li:hover{
        background: v.$base-03;
        color: v.$blanco;
        cursor: pointer;
      }
    }
  }
}