@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-servicios-08{
  padding: 8rem 2.5rem 10rem 2.5rem;
  background: url('./../../build/img/bg-servicios-section-08.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .content-block{
    .item-box{
      h3:nth-of-type(1){
        text-align: center;
        color: v.$base-01;
        font-weight: v.$font-regular-01;
        margin: 0rem 0rem 0rem 0rem;
      }
    }
  }
}