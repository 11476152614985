@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-01{
  position: relative;
  z-index: 400;
  margin-top: -5rem;
  padding: 0rem 0rem 0rem 0rem;
  @include m.mq-md{
    position: static;
  }
  .content-block{
    border-radius: 6rem 6rem 0rem 0rem;
    background: url('./../../build/img/bg-home-section-01.webp');
    background-repeat: no-repeat;
    // background-size: auto 100%;
    background-size: cover;
    background-position: right bottom;

    padding: 5rem 5rem 4rem 5rem;
    display: grid;
    grid-template-columns: 40% 60%;
    @include m.mq-lg{
      background: url('./../../build/img/bg-home-lg-section-01.webp');
      background-repeat: no-repeat;
      // background-size: auto 100%;
      background-size: cover;
      background-position: center bottom;
    }
    @include m.mq-md{
      background: v.$blanco;
      grid-template-columns: 1fr;
      padding: 5rem 0rem 4rem 0rem;
    }
    .item-box{
      @include m.mq-md{
        padding: 4rem 2.5rem 2rem 2.5rem;
      }
      h2:nth-of-type(1){
        color: v.$negro;
        font-family: v.$font-regular-01;
        margin: 0rem 0rem 1rem 0rem;
      }
      h3:nth-of-type(1){
        font-family: v.$font-regular-01;
        font-size: v.$body-04;

        color: v.$base-01;
        text-transform: uppercase;
        border-bottom: .1rem solid v.$base-01;
        padding: 0rem 0rem 1.5rem 0rem;
        margin: 0rem 0rem 1.5rem 0rem;
      }
      p:nth-of-type(1){
        color: v.$negro;
        // font-size: v.$body-05;
      }
    }
  }
}