@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-servicios-07{
  // padding: 8rem 0rem 0rem 0rem;
  @include m.mq-sm{
    position: static;
  }
  .bg{
    background: url('../../../build/img/bg-servicios-section-07.webp'), v.$gris-03;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 11% auto;
    @include m.mq-sm{
      background: none;
    }
    h3:nth-of-type(1){
      padding:4rem 0rem 0rem 0rem;
      color: v.$gris-02;
      text-align: center;
    }
  }
  .content-block{
    // background: v.$gris-03;
    padding: 10rem 16.6% 6rem 0rem;
    display: grid;
    grid-template-columns: 20% 80%;
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 2.5rem;
      padding: 4rem 0rem 2.5rem 0rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: auto;
      @include m.mq-sm{
        padding: 0rem 2.5rem 0rem 2.5rem;
      }
      span{
        // box-shadow: 0rem .5rem .5rem .1rem rgba(0,0,0,.3);
        // width: 9.5rem;
        // height: 9.5rem;
        // border-radius: 50%;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        // background: v.$blanco;
        i:before{
          color: v.$base-01;
          font-size: 10rem;
          @include m.mq-lg{
            font-size: 7rem;
          }
          @include m.mq-sm{
            font-size: 10rem;
          }
        }
        @include m.mq-lg{
          width: 6.5rem;
          height: 6.5rem;
        }
        @include m.mq-sm{
          width: 9.5rem;
          height: 9.5rem;
        }
      }
    }
  }
  .content-block:nth-of-type(1){
    .item-box{
      h4:nth-of-type(1){
        color: v.$base-01;
        margin: 0rem 0rem 2rem 0rem;
      }
      p:nth-of-type(1){
        color: v.$base-01;
        font-size: v.$body-05;
        margin: 0rem 0rem 2rem 0rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        @include m.mq-sm{
          font-size: v.$body-06;
        }
      }
      p:nth-of-type(2){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
    }
  }
  .content-block:nth-of-type(3){
    .item-box{
      h4:nth-of-type(1){
        color: v.$base-01;
        margin: 0rem 0rem 2rem 0rem;
      }
      p:nth-of-type(1){
        color: v.$base-01;
        font-size: v.$body-05;
        margin: 0rem 0rem 2rem 0rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        @include m.mq-sm{
          font-size: v.$body-06;
        }
      }
      p:nth-of-type(2){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
      p:nth-of-type(3){
        color: v.$base-01;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
      p:nth-of-type(4){
        color: v.$base-01;
        font-size: v.$body-05;
        margin: 0rem 0rem 3.5rem 0rem;
        text-transform: uppercase;
        letter-spacing: .2rem;
        @include m.mq-sm{
          font-size: v.$body-06;
        }
      }
      p:nth-of-type(5){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
      p:nth-of-type(6){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
      p:nth-of-type(7){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
      ul:nth-of-type(1){
        margin: 0rem 0rem 1.5rem 1.5rem;
      }
    }
  }
}