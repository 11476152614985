@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-contactanos-04{
  padding: 0rem 0rem 0rem 0rem;
  @include m.mq-sm{
    position: static;
  }
  .content-block{
    background: v.$gris-03;
    // border-radius: 6rem 6rem 0rem 0rem;
    padding: 7rem 16.6% 4rem 16.6%;
    @include m.mq-sm{
      background: v.$blanco;
      grid-template-columns: 1fr;
      padding: 5rem 0rem 4rem 0rem;
    }
    .item-box{
      @include m.mq-sm{
        padding: 4rem 2.5rem 2rem 2.5rem;
      }
      h3:nth-of-type(1){
        color: v.$gris-02;
        margin: 0rem 0rem 3.5rem 0rem;
      }
      p:nth-of-type(1){
        color: v.$base-01;
        border-bottom: .1rem solid v.$base-01;
        font-size: v.$body-05;
        text-transform: uppercase;
        padding: 0rem 0rem 1rem 0rem;
        margin: 0rem 0rem 2rem 0rem;
        letter-spacing: .2rem;
      }
    }
    .item-box:nth-of-type(2){
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      @include m.mq-sm{
        grid-template-columns: 1fr;
      }
      .box{
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
      // .box:nth-of-type(1){
      //   grid-column:1/3;
      //   @include m.mq-sm{
      //     grid-column:1;
      //   }
      // }
    }
  }
}