@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-nosotros-03{
  padding: 0rem 0rem 0rem 0rem;
  @include m.mq-sm{
    position: static;
  }
  .content-block{
    background: v.$gris-03;
    padding: 7rem 0rem 4rem 16.6%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3.5rem;
    border-bottom: .1rem solid v.$base-01;
    @include m.mq-sm{
      background: v.$blanco;
      grid-template-columns: 1fr;
      padding: 5rem 0rem 4rem 0rem;
    }
    .item-box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: auto;
      @include m.mq-sm{
        padding: 0rem 2.5rem 0rem 2.5rem;
      }
      h3:nth-of-type(1){
        color: v.$base-01;
        margin: 0rem 0rem 3.5rem 0rem;
      }
      p:nth-of-type(1){
        color: v.$negro;
        margin: 0rem 0rem 2rem 0rem;
        text-align: justify;
      }
    }
  }
}