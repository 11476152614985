@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-03{
  padding: 3rem 0rem 3rem 0rem;
  background: v.$gradient-alfa-01, url('./../../build/img/bg-home-section-03.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: hard-light;
  @include m.mq-sm{
    background: v.$gradient-alfa-01, url('./../../build/img/bg-home-mobile-section-03.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  h3:nth-of-type(1){
    color: v.$blanco;
    text-align: center;
    margin: 0rem 0rem 4.5rem 0rem;
  }
  .content-block{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @include m.mq-sm{
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // column-gap: 1.5rem;
      // row-gap: 3rem;
      flex-direction: column;
    }
    p{
      color: v.$blanco;
      @include m.mq-sm{
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
}