@use 'base/variables' as v;
@use 'base/mixins' as m;

.section-aliados{
  padding: 8rem 0rem 8rem 0rem;
  h3:nth-of-type(1){
    color: v.$gris-02;
    text-align: center;
    margin: 0rem 0rem 3rem 0rem;
  }
}