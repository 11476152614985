@use 'base/variables' as v;
@use 'base/mixins' as m;

.header{
  background: v.$gris-04;
  padding: 1rem 1.25rem 1rem 1.25rem;
  position: fixed;
  top:0;
  z-index: 2000;
  width: 100%;
  @include m.mq-lg{
    padding: .25rem 1.25rem .25rem 1.25rem;
  }
  @include m.mq-sm{
    box-shadow: 0 0 .5rem 1rem rgba(0,0,0,.1);
    background: v.$blanco;
    padding: 1rem 0rem 1rem 0rem;
  }
  .navbar{
    padding: 0rem 0rem 0rem 0rem;
    // Brand
    .navbar-brand{
      span{
        i:before{
          color: v.$blanco;
          font-size: 4rem;
          @include m.mq-sm{
            color: v.$base-01;
          }
        }
      }
    }
    // Toggler
    .navbar-toggler{
      padding: 0;
      margin: 0;
      outline: none;
      &:focus{
        box-shadow: none;
      }
      span{
        i:before{
          color: v.$blanco;
          font-size: 3.5rem;
          @include m.mq-sm{
            color: v.$base-01;
          }
        }
      }
    }
    // Collapse
    .navbar-collapse{
      // justify-content: flex-end;
      // justify-content: center;
      @include m.mq-sm{
        // border-top: .1rem solid v.$gris-01;
        border-top: .1rem solid v.$base-01;
        margin-top: 1rem;
      }
      .navbar-nav{
        width: 100%;
        gap: 0rem 4.5rem;

        align-items: center;
        justify-content: center;
        .nav-item{
          @include m.mq-sm{
            margin: 1.5rem 0rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .nav-link.active{
            border-bottom: .1rem solid v.$base-01;
            @include m.mq-sm{
              border-bottom: .1rem solid v.$base-01;
            }
          }
          .nav-link{
            color: v.$negro;
            padding: 0rem 0rem 0rem 0rem;
            border-bottom: .1rem solid transparent;
            font-family: v.$font-bold-01;
            @include m.mq-sm{
              color: v.$negro;
              font-family: v.$font-regular-01;
            }
            &:focus{
              border-bottom: .1rem solid v.$base-01;
              @include m.mq-sm{
                border-bottom: .1rem solid v.$base-01;
              }
            }
            &:hover{
              border-bottom: .1rem solid v.$base-01;
              @include m.mq-sm{
                border-bottom: .1rem solid v.$base-01;
              }
            }
          }
        }
      }
    }
  }
}