@use 'base/variables' as v;
@use 'base/mixins' as m;

.footer{
  // background: v.$base-01;
  background: v.$negro;
  padding: 4rem 0rem 4rem 0rem;
  .content-block{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 5rem;
    @include m.mq-sm{
      grid-template-columns: 1fr;
      row-gap: 4rem;
    }
    .item-box{
      p{
        color: v.$blanco;
        margin: 0rem 0rem 1.5rem 0rem;
      }
    }
    .item-box:nth-of-type(1){
      ul{
        border-left:.1rem solid v.$blanco;
        padding-left: 1.8rem;
        display: flex;
        flex-direction: column;
        gap: 1.8rem 0rem;
        @include m.mq-sm{
          border: none;
          padding-left: 0;
        }
        li{
          color: v.$blanco;
          a{
            color: v.$blanco;
            &:hover{
              color: v.$base-03;
            }
          }
        }
      }
    }
    .item-box:nth-of-type(2){
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      ul{
        display: flex;
        flex-direction: row;
        gap: 0rem 1.5rem;
        li{
          color: v.$blanco;
          a{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0rem 1rem;
            color: v.$blanco;
            span{
              i{
                font-size: v.$h2-xl;
              }
            }
            &:hover{
              color: v.$base-03;
            }
          }
        }
      }
      ul:nth-of-type(1){
        margin-bottom: 1.8rem;
      }
    }
    .item-box:nth-of-type(3){
      ul{
        display: flex;
        flex-direction: column;
        gap: 1.8rem 0rem;
        li{
          color: v.$blanco;
          text-align: right;
          @include m.mq-sm{
            text-align: left;
          }
          a{
            color: v.$blanco;
            &:hover{
              color: v.$base-03;
            }
          }
        }
      }
    }
  }
}